.contactus {
    .cu-big-title {
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 600;
        font-size: 2.875em;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
            
        }

        @media screen and (max-width: 991px) {
            font-size: 2.875em;
        }

        @media screen and (max-width: 520px) {
            font-size: 1.75em;
        }

        .text-red {
            color: #FC0226;
        }
    }

    .cu-text {
        color: #fff;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 400;
        font-size: 1.125em;
        line-height: normal;

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1.125em;
        }

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }
    }

    .input-label {
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.375em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        @media screen and (max-width: 667px) {
            font-size: 1em;
        }
        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }
        .red{
            color: #FC0226;
        }
    }

    .form-control {
        border: none;
        border-radius: 0px;
        height: 2.5em;
        color: #0C0C0C;
        font-family: 'Poppins', sans-serif !important;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.375em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        opacity: 1;
        padding-left: 5px;
        padding-right: 5px;
        @media screen and (max-width: 667px) {
            font-size: 1em;
        }
        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }
    }

    .input-transparent {
        // border-bottom: 3px solid #F9C258;
        border-bottom: 0.6px solid #555;
        &:focus{
            border-bottom: 2px solid #F9C258;
        }
    }

    .groupLabel-input {
        background-color: #ffffff;
        align-items: center;
    
        &.overflow{
          overflow: initial;
        }
    }


    .cu-title {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        font-size: 2.875em;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
            
        }

        @media screen and (max-width: 991px) {
            font-size: 2.875em;
        }

        @media screen and (max-width: 520px) {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.75em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;
        }
    }

    .cu-subtitle {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 2.25em;

        @media screen and (min-width: 1800px) {
            font-size: 2.3vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1.75em;
        }

        @media screen and (max-width: 520px) {
            font-size: 1.25em;
        }
    }
}