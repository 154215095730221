.company {
    .header{
        height: 90vh;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 80vh;
        }

        .highresscreen {
            @media (min-width: 2000px) {
                &.col-lg-5 {
                    flex: 0 0 83.333333% !important;
                    max-width: 83.333333% !important;
                }
            }
        }

        .head-small-title {
            color: #F1F1F1;
            leading-trim: both;
            text-edge: cap;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 1em;
            text-transform: capitalize;

            @media screen and (min-width: 1800px) {
                font-size: .7vw;
            }

            @media screen and (max-width: 520px) {
                font-size: 0.8125em;
            }
        }

        .head-big-title {
            color: #FFF;
            leading-trim: both;
            text-edge: cap;
            font-style: normal;
            font-weight: 600;
            font-size: 2.875em;
            line-height: 1.2em;
            text-transform: capitalize;

            @media screen and (min-width: 1800px) {
                font-size: 2.8vw
            }

            @media screen and (max-width: 520px) {
                font-size: 1.75em;
            }
        }

        .content-text-container {
            width: 99%;
        }

        .content-text {
            color: #E6E6F3;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125em;
            line-height: 1.5em;

            @media screen and (min-width: 1800px) {
                font-size: 1vw;
            }

            @media screen and (max-width: 991px) {
                font-size: 1.125em;
            }

            @media screen and (max-width: 520px) {
                color: #E6E6F3;
                leading-trim: both;
                text-edge: cap;
                font-size: 1em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .white-divider {
            width: 99%;
            height: .6px;
            background-color: #E6E6F3;
        }
    }

    &.home {
        .header {
            background: url('../../img/headerBackground/home.png');
            background-size: cover;
            background-repeat: no-repeat;
            
            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }
        }
    }

    &.aboutus {
        .header {
            background: url('../../img/headerBackground/aboutus.png');
            background-size: cover;
            background-repeat: no-repeat;
            
            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &.companynews {
        .header {
            background: url('../../img/headerBackground/companynews.png');
            background-size: cover;
            background-repeat: no-repeat;
            
            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background-position: 70% 100%;
                background-repeat: no-repeat;
            }
        }
    }

    &.contactus {
        .header {
            background: url('../../img/headerBackground/contactus.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
            
            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background: url('../../img/headerBackground/contactus.png');
                height: 90vh;
                background-position: 100% 0%;
                background-size: cover;
                background-repeat: no-repeat;
            }

            @media screen and (min-width: 1024px) and (min-height: 1200px) {
                height: 60vh;
            }
        }
    }

    &.assetsecurity {
        .header {
            background: url('../../img/headerBackground/assetsecurity.png');
            background-size: cover;
            background-repeat: no-repeat;
            
            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background-position: 85% 100%;
                background-repeat: no-repeat;
            }
        }
    }

    &.award {
        .header {
            background: url('../../img/headerBackground/award.png');
            background-size: cover;
            background-repeat: no-repeat;
            
            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background-position: 80% 100%;
                background-repeat: no-repeat;
            }
        }
    }

    .ultimatebroker {
        background-color: #ffffff;
        background-image: url('../../img/company/ultimateOuterBg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;

        @media screen and (max-width: 520px) {
            background-image: url('../../img/company/ultimateOuterBgMobile.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
        }

        .contentbg {
            background: url('../../img/company/ultimatebroker.png');
            background-size: cover;
            background-repeat: no-repeat;
            box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.46);

            @media screen and (max-width: 767px) {
                background: url('../../img/company/ultimatebrokerMobile.png');
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        .section-title {
            color: #ffffff;
            leading-trim: both;
            text-edge: cap;
            font-size: 2.875em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;

            .text-gold {
                color: #F9C258;
            }

            @media screen and (max-width: 520px) {
                color: #ffffff;
                leading-trim: both;
                text-edge: cap;
                font-size: 1.75em;
                font-style: normal;
                font-weight: 600;
                white-space: break-all;
                line-height: normal;
            }

            @media screen and (min-width: 1800px) {
                font-size: 2.8vw;
            }
        }

        .section-content {
            color: #E6E6F3;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            @media screen and (max-width: 520px) {
                color: #ffffff;
                font-size: 1em;
            }

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }
        }

        .white-stroke {
            width: 100%;
            height: 6px;
            background-color: #ffffff;
        }

        .phone-container {
            margin-top: -5%;

            img {
                width: 50%;

                @media screen and (min-width: 768px) and (max-width: 991px) {
                    width: 100%;
                }
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
                margin-top: -3%;
            }
        }

        .content-bold {
            color: #0C0C0C;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.5em;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3em;

            @media screen and (max-width: 520px) {
                font-size: 1.125em;
            }

            @media screen and (min-width: 1800px) {
                font-size: 1vw;
            }
        }

        .content-light {
            color: #555;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3em;

            @media screen and (max-width: 520px) {
                font-size: 1em;
            }

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }
        }

        .sectiontwo {
            min-height: 60vh;
            @media screen and (min-width: 1800px){
                min-height: auto;
            }
            @media screen and (min-width: 768px) and (max-width: 991px) {
                min-height: 30vh;
            }
        }
    }

    .mission {
        background: url('../../img/company/missionBg.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left;

        .missionTitle {
            color: #0C0C0C;
            leading-trim: both;
            text-edge: cap;
            font-size: 2.875em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;

            .red {
                color: #FC0226;
            }

            @media screen and (min-width: 1800px) {
                font-size: 2.8vw;
                
            }

            @media screen and (max-width: 520px) {
                color: #0C0C0C;
                leading-trim: both;
                text-edge: cap;
                font-size: 1.75em;
                font-weight: 600;
                line-height: 120%;
                text-transform: capitalize;
            }
        }

        .missionSubTitle {
            color: #0C0C0C;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }

            @media screen and (max-width: 520px) {
                color: #0C0C0C;
                leading-trim: both;
                text-edge: cap;
                font-size: 1em;
                font-style: normal;
                font-weight: 400;
                line-height: 1.3em;
            }
        }

        .icon {
            height: 4em;

            &.inactive {
                display: block;
            }

            &.active {
                display: none;
            }

            @media screen and (min-width: 1441px) {
                height: 4em;
            }

            @media screen and (max-width: 520px) {
                height: 3.125em;
            }
            @media screen and (min-width: 1800px) {
                height: 2vw;
            }
        }

        .weprovidedtextContainer {
            width: 90%;
            justify-content: center;
            margin-left: auto;
            margin-right: 0;

            @media screen and (max-width: 520px) {
                width: 95%;
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (min-width: 521px) and (max-width: 767px) {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 70%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .weprovidedtext {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media screen and (max-width: 520px) {
                font-size: 1em;
            }

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }
        }

        .missionBox-container {
            width: 90%;
            justify-content: center;
            margin-left: auto;
            margin-right: 0;

            @media screen and (max-width: 520px) {
                width: 95%;
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (min-width: 521px) and (max-width: 767px) {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 70%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .missionBox {
            background: #FFF;
            box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.20);
            height: 23vh;
            position: relative;

            @media screen and (min-width: 768px) and (max-width: 991px) {
                height: 20vh;
            }

            .missionName {
                color: #0C0C0C;
                font-family: 'Inter', sans-serif;
                font-size: 1.125em;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                white-space: break-spaces;

                @media screen and (min-width: 1800px) {
                    font-size: .8vw;
                }

                @media screen and (max-width: 520px) {
                    font-size: 1em;
                }

                @media screen and (max-width: 300px) {
                    font-size: 0.8em;
                }
            }

            .corner {
                position: absolute;
                right: 0;
                top: 0;
                display: none;

                @media screen and (max-width: 520px) {
                    svg {
                        width: 3.125em;
                        height: 3.125em;
                    }
                }
            }

            &:hover {
                background-image: url('../../img/assets/assetHoverBg.png');
                box-shadow: 14px 30px 50px 0px rgba(207, 0, 0, 0.35);
                background-image: 85% auto;

                .missionName {
                    color: #ffffff;
                }

                .icon {
                    &.active {
                        display: block;
                    }

                    &.inactive {
                        display: none;
                    }
                }

                .corner {
                    display: block;
                }
            }
        }
    }

    .experience {
        background: linear-gradient(180deg, #FFF 5%, rgba(255, 255, 255, 0.00) 24.29%, #FFF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;

        .experienceBg {
            min-height: 60vh;

            .container {
                min-height: 60vh;

                @media screen and (max-width: 991px) {
                    min-height: 40vh;
                }
            }

            @media screen and (max-width: 991px) {
                min-height: 40vh;
            }
        }

        .bgImage {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        .blackBg {
            background: #000 !important;
        }

        .experienceTitle {
            color: #000;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-size: 2.875em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;

            .gold {
                color: var(--f-9-c-258, #F9C258);
            }

            @media screen and (max-width: 520px) {
                font-size: 1.25em;
            }
            @media screen and (min-width: 1800px) {
                font-size: 2.8vw;
                
            }
        }

        .left-corner {
            position: absolute;
            left: 0;
            top: 0;

            @media screen and (max-width: 991px) {
                width: 10%;
            }
        }

        .right-corner {
            position: absolute;
            right: 0;
            bottom: 0;
            transform: rotate(-180deg);

            @media screen and (max-width: 991px) {
                width: 10%;
            }
        }
    }

    .client {
        height: 90vh;
        @media screen and (max-width: 667px){
            min-height: 130vh;
        }
        @media screen and (max-width: 991px) {
            height: auto;
            min-height: 100vh;
        }
        .quote-img{
            @media screen and (max-width: 520px) {
                width: 4.125em;
                height: 3.75em;
            }
        }
        .bottom-quote-img{
            @media screen and (max-width: 520px) {
                width: 10em;
                height: 9em;
                margin-right: -3em;
            }
        }
        .client-content{
            height: 90vh;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @media screen and (max-width: 667px) {
                height: auto;
                min-height: 130vh;
            }
           

        }
        .bgWhite{
            background-color: #ffffff;
            height: 90vh;
            @media screen and (max-width: 991px){
                height: 35vh;
            }
        }
        .sectionOne{
            @media screen and (max-width: 991px) {
                height: 30vh;
                .text-container{
                    display: flex;
                    height: 100%;
                    align-items: center;
                }
            }
            
        }
        .sectionTwo{
            @media screen and (max-width: 667px) {
                height: auto;
                min-height: 100vh;
            }
            @media screen and (max-width: 991px) {
                height: 70vh;
            }
        }
        .client-mobile-container{
            @media screen and (max-width: 667px){
                margin-top: -10%;
                min-height: 100vh;
            }
            @media screen and (min-width: 668px) and (max-width: 991px) {
                margin-top: -5%;
                height: 60vh;
            }
        }
        .bgRed{
            background: url('../../img/company/clientBg.png');
            background-size: cover;
            background-repeat: no-repeat;
            height: 90vh;
            @media screen and (max-width: 667px){
                min-height: 110vh;
            }
            @media screen and (max-width: 991px){
                height: 70vh;
            }
            
        }
        .client-trustedbytraders {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.875em;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
            @media screen and (max-width: 520px) {
                font-size: 1.125em;
            }
            @media screen and (min-width: 1800px) {
                font-size: 1.2vw;
            }
        }

        .client-hearclientsays {
            color: #FC0226;
            leading-trim: both;
            text-edge: cap;
            font-size: 2.875em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;
            white-space: break-spaces;
            @media screen and (max-width: 520px) {
                font-size: 1.75em;
            }
            @media screen and (min-width: 1800px) {
                font-size: 2.8vw;
            }
        }

        .comment-box {
            background-image: url('../../img/company/commentboxBg.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .comment-list {
            display: grid;
            grid-gap: calc(var(--gutter) * 1.5 );
            // grid-template-columns: 1rem;
            // grid-template-rows: minmax(10rem, 1fr);
            grid-auto-flow: column;
            // grid-auto-columns: 320px; // small viewports

            @media (min-width: 800px) {
                grid-auto-columns: calc(var(--gutter) * 12);
            }

            @media (min-width: 1800px) {
                grid-auto-columns: calc(var(--gutter) * 24);
            }

            overflow-x: scroll;
            scroll-snap-type: x proximity;
            scroll-snap-points-x: repeat(100%);
            scroll-snap-type: mandatory;
            scroll-snap-destination: 100% 0%;
            // padding-bottom: calc(.75 * var(--gutter));
            // margin-bottom: calc(-.25 * var(--gutter));
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }

            @media (min-width: 800px) {

                // yeah make this large-viewport only
                &:hover {
                    .item {
                        opacity: 0.6;
                        filter: blur(2px);
                        // transition-delay: 0.3s;
                    }
                }
            }

            
        }

        .client-message{
            color: #0C0C0C;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; 
            text-transform: capitalize;
            @media screen and (max-width: 520px) {
                font-size: 1em;
            }
            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }
        }

        .client-name{
            color: #F9C258;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.25em;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 24px */
            text-transform: capitalize;

            @media screen and (max-width: 520px) {
                font-size: 1.125em;
            }
            @media screen and (min-width: 1800px) {
                font-size: 1vw;
            }
        }
        .client-position{
            color: #555;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 21.6px */
            text-transform: capitalize;

            @media screen and (max-width: 520px) {
                font-size: 1em;
            }
            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }
        }
        .arrow{
            &:hover{
                svg path{
                    fill: #FC0226;
                }
                svg circle{
                    stroke: #FC0226;
                }
            }
        }
        .pagination{
            position: absolute;
            bottom: 2.5%;
            @media screen and (max-width: 667px){
                bottom: 0%;
            }
        }

        .page-bullet{
            width: 5px;
            height: 5px;
            background-color: #ffffff;
            &.active{
                background-color: #F9C258;
                width: 4.375em;
            }
        }
    }

}