.reason {
    background: url('../../img/reason/reasonBg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 100vh;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background: url('../../img/reason/reasonBgMobile.png');
        background-position: center;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 667px) {
        background: url('../../img/reason/reasonBgMobile.png');
        background-position: bottom;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px) {
        height: auto !important;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 80vh !important;
        background-position: right;
    }

    .highresscreen {
        @media (min-width: 2000px) {
            &.col-lg-5 {
                flex: 0 0 66.666667% !important;
                max-width: 66.666667% !important;
            }
        }
    }

    .reason-title {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        font-size: 2.875em;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
            
        }

        @media screen and (max-width: 991px) {
            font-size: 2.875em;
        }

        @media screen and (max-width: 520px) {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.75em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;
        }
    }

    .reason-subtitle {
        color: #555;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 1.125em;

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1.125em;
        }

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }
    }

    .icon {
        height: 3em;

        &.inactive {
            display: block;
        }

        &.active {
            display: none;
        }

        @media screen and (min-width: 1441px) {
            height: 3vw;
        }

        @media screen and (max-width: 991px) {
            height: 3em;
        }

        @media screen and (max-width: 520px) {
            height: 2.5em;
        }
    }

    .reason-box {
        flex: none;
        width: 25vw;
        @media screen and (min-width: 1441px) {
            width: 14vw;
            margin-right: 1vw !important;
        }

        @media screen and (max-width: 991px) {
            width: 15em;
        }

        &:hover {
            .icon {
                &.inactive {
                    display: none;
                }

                &.active {
                    display: block;
                }
            }
        }
    }

    .reason-name {
        color: #000;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 700;
        // line-height: normal;

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1.125em;
        }
    }

    .reason-description {
        color: #555;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (min-width: 1441px) {
            font-size: 1.111vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1em;
        }
    }

    .greyDivider{
        background-color: #555;
        height: 0.6px;
        width: 100%;
    }

    .reason-mobile-container{
        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 40vh;
        }
        @media screen and (max-height: 800px){
            height: 50vh;
        }
        @media screen and (max-height: 720px){
            height: 60vh;
        }
        @media screen and (max-width: 300px){
            height: 65vh;
        }
    }

    .pagination{
        position: absolute;
        bottom: 0;
    }

    .page-bullet{
        width: 5px;
        height: 5px;
        background-color: #555555;
        &.active{
            background-color: #FC0226;
            width: 4.375em;
        }
    }

    .name-container{
        height: 40px;
    }
    .reason-mobile-box{
        height: 180px;

        @media screen and (max-width: 300px){
            height: 200px;
        }
        &:hover, &:focus, &:active {
            .icon {
                &.inactive {
                    display: none;
                }

                &.active {
                    display: block;
                }
            }
        }
    }
}