.experience{
    background: url('../../img/home/experienceBg.png');
    // background: #000;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (min-width: 992px) {
        height: 120vh;
    }
    @media screen and (max-width: 991px) {
        height: 80vh;
    }
    @media screen and (min-width: 800px) and (max-width: 881px) {
        height: 70vh;
    }
    @media screen and (max-width: 667px) {
        // height: 180vh;
        height: auto;
    }

    @media screen and (max-width: 667px) and (min-height: 750px) {
        // height: 155vh;
        height: auto;
    }
    @media screen and (max-width: 667px) and (min-height: 860px){
        // height: 145vh;
        height: auto;
    }
    @media screen and (max-width: 667px) and (min-height: 880px){
        // height: 130vh;
        height: auto;
    }

    @media screen and (min-width: 1024px) and (min-height: 1200px) {
        height: 80vh;
    }


    .experience-phone{
        width: 50%;
        @media screen and (max-width: 991px) {
            width: 80%;
        }
        @media screen and (max-width: 667px) {
            width: 50%;
        }
    }
    

    .infoBox-absolute{
        position: absolute;
        width: 90%;
        bottom: -15%;
        z-index: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 991px) {
            position: absolute;
            bottom: -15%;
        }

        @media screen and (max-width: 667px) {
            position: relative;
            bottom: auto;
            width: 100%;
        }
    }

    .icon{
        width: 60px;
    }

    .infoBox-iconContainer{
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .infoBox{
        background: #FFF;
        box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.20);
    }

    .infoBox-name-container{
        height: 40px;
        display: flex;
            align-items: center;
       
        @media screen and (max-width: 667px) {
            height: auto;
            display: flex;
            align-items: center;
        }
    }

    .infoBox-name{
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.25em;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        /* 24px */
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 1vw
        }

        @media screen and (max-width: 667px) {
            font-size: 1,25em;
        }
    }

    .infoBox-content{
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        @media screen and (max-width: 667px) {
            font-size: 1em;
        }
        @media screen and (min-width: 1800px) {
            font-size: .7vw;
        }
    }
}