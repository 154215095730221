.deposit {
    background: url('../../img/trading/withdrawBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    min-height: 80vh;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background-size: cover;
        background-position: 65% 75%;
        background-repeat: no-repeat;
        min-height: 60vh;
    }

    @media screen and (max-width: 767px) {
        background: url('../../img/trading/withdrawBg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .section-title {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 2.875em;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-transform: capitalize;

        .text-gold {
            color: #F9C258;
        }

        @media screen and (max-width: 520px) {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.75em;
            font-style: normal;
            font-weight: 600;
            white-space: break-all;
            line-height: normal;
        }

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }
    }

    .section-content {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.5em;
        font-style: normal;
        font-weight: 600;
        line-height: 2em;
        text-transform: capitalize;

        @media screen and (max-width: 667px) {
            font-size: 1.125em;
        }

        @media screen and (min-width: 1800px) {
            font-size: .7vw;
        }
    }

    .section-description {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 667px) {
            font-size: 1em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }
    }

    .noDepositFee {
        color: #FC0226;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.5em;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-transform: capitalize;

        @media screen and (max-width: 667px) {
            font-size: 1.125em;
        }

        @media screen and (min-width: 1800px) {
            font-size: .9vw;
        }
    }

    .mockup-form-container {
        background: url('../../img/trading/depositBg.png');
        background-size: cover;
        border-radius: 40px;
        background-position: center;
        padding-top: 10vh;
        padding-bottom: 8vh;
        @media screen and (max-width: 991px) {
            padding-top: 8vh;
            padding-bottom: 6vh;
        }
        @media screen and (max-width: 667px) {
            border-radius: 20px;
        }
    }

    .user-icon {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 100%;
        }
        @media screen and (max-width: 667px) {
            svg {
                width: 80%;
            }
        }
    }

    .mockup-input-container {
        background-color: #ffffff;
        border-radius: 5px;
        overflow: hidden;
    }

    .forgotPassword {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: .875em;
        font-style: normal;
        font-weight: 400;
        line-height: 2em;
        text-transform: capitalize;

        @media screen and (max-width: 667px) {
            font-size: 12px
        }

        @media screen and (min-width: 1800px) {
            font-size: .7vw;
        }
    }

    .mockup-btn{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
        bottom: 0;
        width: 70%;
    }

    .mockup-login-btn {
        background: #FC0226;
        transition: transform 500ms ease-out;
        position: relative;
        overflow: hidden;
        border: 1px solid #FC0226;
        width: 100%;

        .redBtnText {
            color: #ffffff;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-weight: 600;
            line-height: normal;

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }

            @media screen and (max-width: 520px) {
                font-size: 1rem;
            }
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}