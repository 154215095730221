:root {
    --primary-color: #FC0226;
    --text-primary: #FC0226;
    --text-seconday: #0C0C0C;
    --text-error: #C10808;
    --menu-active-text-color: #CDA351;
    --menu-inactive-text-color: #E6E6F3;
    --gutter: 1.5rem;
    --bg-color: #edf2f7; // bg-gray-200
    --speed: 0.3s;
}

body {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    line-height: 1 !important;
    touch-action: pan-x pan-y;
    background-color: #ffffff;

    scrollbar-base-color: rgb(249, 194, 88);
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #F9C258;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: #F9C258;
    scrollbar-shadow-color: #F9C258;
    scrollbar-dark-shadow-color: #F9C258;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.device-max-width-size {
    width: 100%;

    @media screen and (min-width: 2101px) {
        // max-width: 2100px;
        overflow-x: hidden;
    }
}

.device-max-height-size {
    @media screen and (min-width: 1920px) {
        max-height: 70vh;
        overflow-x: hidden;
    }
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-8 {
    line-height: 105%;
    font-size: 0.5em;
}

.font-10 {
    font-size: 0.625em;
}

.font-12 {
    font-size: 0.75em;
}

.font-14 {
    font-size: 0.875em;
}

.font-16 {
    font-size: 1em;
}

.font-18 {
    font-size: 1.125em;
}

.font-19 {
    font-size: 1.1875em;
}

.font-20 {
    font-size: 1.25em;
}

.font-22 {
    font-size: 1.375em;
}

.font-24 {
    font-size: 1.5em;
}

.font-25 {
    font-size: 1.5625em;
}

.font-26 {
    font-size: 1.625em;
}

.font-28 {
    font-size: 1.75em;
}

.font-30 {
    font-size: 1.875em;
}

.font-32 {
    font-size: 2em;
}

.font-34 {
    font-size: 2.125em;
}

.font-35 {
    font-size: 2.1875em;
}

.font-40 {
    font-size: 2.5em;
}

.font-100 {
    font-size: 6.25em;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-sm {
    line-height: 1.25 !important;
}

.lh-base {
    line-height: 1.5 !important;
}

.lh-lg {
    line-height: 2 !important;
}


.line-height-145percent {
    line-height: 145.5%;
}

.line-height-100percent {
    line-height: 100.5%;
}

.text-gold {
    color: #CDA351;
}

.text-yellow{
    color: #F9C258 !important;
}

.text-red {
    color: #FC0226 !important;
}

.text-grey {
    color: #555555;
}

@media (max-width: 2099px) {
    .container {
        max-width: 90% !important;
    }
}

@media screen and (max-width: 300px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 2100px) {
    .container {
        max-width: 70% !important;
    }
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tradingview-widget-copyright,
.js-copyright-label {
    display: none !important;
    opacity: 0;
}

.trading-marquee {
    height: 10vh;
    position: fixed;
    bottom: 0;
    background-color: #251515;
    width: 100%;
    max-height: 150px;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 8vh;
    }
}

.scrolltotop {
    background-color: #FC0226 !important;
    bottom: 12% !important;
    right: 2% !important;
    opacity: 0.7;
    border-radius: 40px !important;

    &:hover,
    &:active,
    &:focus {
        opacity: 1;
        outline: none !important;
    }

    @media screen and (min-width: 2101px) {
        position: sticky;
    }
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}

.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.active.zoom-in {
    animation: zoom-in 1s ease-in;
}

.active.fade-top {
    animation: fade-top 1s ease-in;
}

.active.fade-left {
    animation: fade-left 1s ease-in;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

.active.bounce {
    animation: bounce 1s ease-in;
}

.active.flip {
    animation: flip 1.5s ease-in;
}

@keyframes zoom-in {
    0% {
        transform: scale(1, 1);
        opacity: 0;
    }

    50% {
        transform: scale(1.2, 1.2);
        opacity: 0.5;
    }

    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes flip {
    0% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    100% {
        transform: perspective(400px);
    }
}

.ReactModal__Body--open {
    .fixed-top {
        z-index: 0 !important;
    }
}

.ReactModal__Overlay--after-open,
.modal-backdrop {
    background: rgba(50, 48, 48, 0.86) !important;
    backdrop-filter: blur(10px) !important;
    opacity: .9 !important;
}


.redBtn {
    background: #FC0226;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #FC0226;
    width: 100%;

    .redBtnText {
        color: #ffffff;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-weight: 600;
        line-height: normal;

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: #FC0226 !important;
        box-shadow: 0 0.5em 0.5em -0.4em rgba(252, 2, 38, 0.5);
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.goldBtn {
    background: #CDA351;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #CDA351;

    .goldBtnText {
        color: #ffffff;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-weight: 600;
        line-height: normal;

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: #CDA351 !important;
        box-shadow: 0 0.5em 0.5em -0.4em rgba(205, 163, 81, 0.5);
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}


.yellowBtn {
    background: #F9C258;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #F9C258;

    .yellowBtnText {
        color: #ffffff;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-weight: 600;
        line-height: normal;

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    &:hover,
    &:active,
    &:focus {
        background: #F9C258 !important;
        box-shadow: 0 0.5em 0.5em -0.4em rgba(249, 194, 88, 0.5);
        transform: translateY(-0.25em);

        &::before {
            left: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}


.modal-content .modal-body {
    line-height: normal;
}

// if menu open set body cannot scroll
.menu-toggle {
    .main-navbar {
        z-index: 99999 !important;
    }

    &.index-page {
        overflow-y: hidden !important;
    }
}

.privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(252, 2, 38, 0.80) 0%, rgba(72, 0, 10, 0.80) 77.74%, #3C0000 99.94%), radial-gradient(55.58% 39.58% at 28.54% 58.18%, rgba(252, 2, 38, 0.26) 0%, rgba(252, 2, 38, 0.75) 40.42%, #FC0226 98.08%), lightgray -214.407px -206.211px / 117.662% 125.521% no-repeat, linear-gradient(180deg, #F00 0%, #710000 100%);
    // background-color: #FAB705;
    z-index: 99 !important;
}

.contents {
    overflow-x: hidden;
}

.accordion-section {
    .expand-arrow{
        width: 60%;
    }
    .accordion-title {
        background-color: #FC0226;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .column-title {
        color: var(--ffffff, #FFF);
        text-align: right;
        leading-trim: both;
        text-edge: cap;
        font-size: 1em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .product-symbol {
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .product-spread {
        color: #FF3838;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .accordion-item:nth-child(odd) {
        border: 1px solid var(--e-6-e-6-f-3, #E6E6F3);
        border-top: 0px !important;
        background: var(--ffffff, #FFF);
    }

    .accordion-item:nth-child(even) {
        border: 1px solid var(--e-6-e-6-f-3, #E6E6F3);
        border-top: 0px !important;
        background: #FFF5F5;
    }

    tr,
    td {
        border: none !important;
    }

    .product-label {
        color: #555;
        leading-trim: both;
        text-edge: cap;
        font-size: 1em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .product-value {
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-size: 1em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.error{
    color: #FF3838;
    font-weight: 400;
    font-size: 0.875em;
}

.head-big-title{
    text-transform: capitalize;
}

.accordion-item{
    background-color: transparent !important;
}

.faq-title {
    leading-trim: both;
    text-edge: cap;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;

    &.active {
        color: #FC0226;
    }

    &.inactive {
        color: #0C0C0C;
    }

    @media screen and (min-width: 1800px) {
        font-size: 1.8vw;
    }

    @media screen and (min-width: 767px) and (max-width: 991px) {
        font-size: 1.5em;
    }

    @media screen and (max-width: 767px) {
        font-size: 1.25em;
    }
}

.faq-description {
    color: #555;
    leading-trim: both;
    text-edge: cap;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: break-spaces;

    @media screen and (max-width: 667px) {
        font-size: 1em;
    }
    @media screen and (min-width: 1800px) {
        font-size: .8vw;
    }
}

.expand-arrow {
    width: 10%;
    @media screen and (min-width: 767px) and (max-width: 991px) {
        width: 20%;
    }
    @media screen and (max-width: 767px) {
        width: 60%;
    }
}

.border-none {
    border: none;
   
    &.active{
        border-bottom: 3px solid #FC0226;
    }
    &.inactive{
        border-bottom: 0.6px solid #555;
    }
    @media screen and (min-width: 1800px) {
        &.inactive{
            border-bottom: 2px solid #555;
        }
        &.active{
            border-bottom: 2px solid #FC0226;
        }
    }

    @media screen and (max-width: 767px) {
        &.active{
            border-bottom: 1px solid #FC0226;
        }
        &.inactive{
            border-bottom: 1px solid #555;
        }
    }
}

.slider-box {
    // flex: none;
    // width: 25vw;
    // @media screen and (min-width: 1441px) {
    //     width: 20vw;
    //     margin-right: 1vw !important;
    // }

    // @media screen and (max-width: 991px) {
    //     width: 15em;
    // }

    .icon {
        height: 3em;

        &.inactive {
            display: block;
        }

        &.active {
            display: none;
        }

        @media screen and (min-width: 1441px) {
            height: 3vw;
        }

        @media screen and (max-width: 991px) {
            height: 3em;
        }

        @media screen and (max-width: 520px) {
            height: 2.5em;
        }
    }

    &:hover {
        .icon {
            &.inactive {
                display: none;
            }

            &.active {
                display: block;
            }
        }
    }
}
.name-container{
    height: 50px;
}

.slider-name {
    color: #000;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 700;
    // line-height: normal;

    @media screen and (min-width: 1800px) {
        font-size: 1vw;
    }

    @media screen and (max-width: 991px) {
        font-size: 1.125em;
    }
}

.slider-description {
    color: #555;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (min-width: 1441px) {
        font-size: 1.111vw;
    }

    @media screen and (max-width: 991px) {
        font-size: 1em;
    }
}

.greyDivider{
    background-color: #555;
    height: 0.6px;
    width: 100%;
}

.slider-mobile-container{
    // @media screen and (min-width: 768px) and (max-width: 991px) {
    //     height: auto;
    //     &.whytradebostforex{
    //         height: auto;
    //     }
    // }
    // @media screen and (max-height: 800px){
    //     height: auto;
    //     &.whytradebostforex{
    //         height: auto;
    //     }
    // }
    // @media screen and (max-height: 720px){
    //     height: auto;
    //     &.whytradebostforex{
    //         height: auto;
    //     }
    // }
    // @media screen and (max-width: 300px){
    //     height: auto;
    //     &.whytradebostforex{
    //         height: auto;
    //     }
    // }
    height: auto;
}

.pagination{
    position: absolute;
    bottom: 0;
}

.page-bullet{
    width: 5px;
    height: 5px;
    background-color: #555555;
    &.active{
        background-color: #FC0226;
        width: 4.375em;
    }
}

.slider-mobile-box{
    height: 200px;
    &.whytradebostforex{
        height: 250px;
    }

    .icon {
        height: 3em;

        &.inactive {
            display: block;
        }

        &.active {
            display: none;
        }

        @media screen and (min-width: 1441px) {
            height: 3vw;
        }

        @media screen and (max-width: 991px) {
            height: 3em;
        }

        @media screen and (max-width: 520px) {
            height: 2.5em;
        }
    }
    &:hover, &:focus, &:active {
        .icon {
            &.inactive {
                display: none;
            }

            &.active {
                display: block;
            }
        }
    }
}

.text-underline{
    text-decoration: underline !important;

    &:hover{
        text-decoration: none !important;
    }
}

.cursor-pointer{
    cursor: pointer;
}