.main-navbar {
    flex-direction: column;
    min-height: auto;

    &.navbar-transparent {
        padding-bottom: 20px;
        @media screen and (max-width: 300px) {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
        }
    }

    @media screen and (min-width: 768px) {
        .language-menu {
            &.active {
                display: block !important;
            }
    
            &.inactive {
                display: none;
            }
    
            .dropdown-menu {
                min-width: auto !important;
            }
        }
    }

    

    &.logo-white {

        .secondRowNavbar{
            display: block;
        }

        @media screen and (min-width: 768px) {
            .language-menu {
                display: block;
            }
        }

        .red,
        .hamburger-menu-container {
            display: none;
        }
    }

    &.logo-red {
        box-shadow: none !important;

        .white,
        .secondRowNavbar {
            display: none;
        }
        @media screen and (min-width: 768px) {
            .language-menu {
                display: none;
            }
        }

        .red,
        .hamburger-menu-container {
            display: block;
        }
    }

    @media screen and (max-width: 992px) {
        .secondRowNavbar {
            display: none !important;
        }
    }

    &.bg-white {
        background-color: #ffffff;
    }

    .lang-icon {
        width: 1.75em;
        aspect-ratio: 1;
    }

    .dropdown-item {
        &:hover {
            background-color: transparent;

            .menu-text {
                color: #F9C258;
                font-weight: 700;
            }
        }
    }

    .navbar-nav {
        .nav-link:not(.btn) {
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: #000000;
        }
    }

    .nav-link:not(.btn) {

        &:active,
        &:hover,
        &:focus {
            background-color: transparent !important;
        }
    }
}

.drawer-menu {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    // overflow-y: scroll;
    position: relative;
    width: 100%;
    z-index: 999 !important;
    // padding-top: 5%;

    @media screen and (max-width: 767px) {
        .container{
            padding-top: 20%;
        }
        // padding-top: 20%;
    }

    .logo-top-section {
        top: 0;
        left: 0;
    }

    .drawer-header-logo {
        width: 50%;
    }

    .parent-menu-text {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.5em;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-transform: capitalize;
        &:hover, &.active {
            color: #F9C258;
            cursor: pointer;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1.2vw;
        }
    }

    .child-menu-text {
        color: #E6E6F3;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        text-transform: capitalize;
        letter-spacing: 0.5px;

        &:hover, &.active {
            letter-spacing: 0px;
            color: #F9C258;
            font-style: normal;
            font-weight: 600;
            cursor: pointer;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }
    }

    .lang-icon {
        width: 1.75em;
        aspect-ratio: 1;
    }

    .dropdown-menu {
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(5px);
        margin-top: 0px;
        margin-bottom: 0px;
        min-width: 15rem;
        position: absolute !important;

        &::before {
            display: none;
        }

        .dropdown-item {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .language-menu {
        .dropdown-menu {
            min-width: auto !important;
        }
    }

    .menu-text {
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        color: #fff;
        &:hover, &.active{
            color: #F9C258;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 991px) {
    .sidebar-collapse .navbar .navbar-translate {
        width: 80%;
    }
}

.mobile-parent-menu-text{
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    @media screen and (max-width: 300px) {
        font-size: 1.125em;
    }
    &:hover, &.active{
        color: #F9C258;
        font-weight: 700;
    }
}

.mobile-child-menu-text{
    color: #E6E6F3 !important;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 19.8px */
    text-transform: capitalize;
    @media screen and (max-width: 300px) {
        font-size: 1em;
    }

    &:hover, &.active{
        color: #CDA351;
        text-decoration-line: none !important;
    }
}

.mobile-language{
    position: absolute;
    bottom: 5%;
    left: 0%;
    z-index: 999;

    // @media screen and (max-width: 767px) {
    //     bottom: 20%;
    // }
}

.drawer-copyright{
    position: absolute;
    bottom: 5%;
    left: 0%;
    width: 100%;
}