
.rightaccount{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 50%, #FFF 100%), url('../../img/home/rightAccountBg.png') lightgray -48px 0px / 106.667% 124.878% no-repeat;
    
    @media screen and (max-width: 667px) {
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 50%, #FFF 100%);
    }
    .section-title {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 2.875em;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-transform: capitalize;

        .text-gold {
            color: #F9C258;
        }

        @media screen and (max-width: 520px) {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.75em;
            font-style: normal;
            font-weight: 600;
            white-space: break-all;
            line-height: normal;
        }

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }
    }
    .section-description {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 667px) {
            font-size: 1em;
        }
        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }
    }
    .rightaccount-box {
        &.accountOne{
            background: linear-gradient(232deg, rgba(255, 255, 255, 0.72) 0.98%, rgba(255, 255, 255, 0.96) 58.1%), url('../../img/home/1.png');
            // background-blend-mode: normal, saturation, color, normal, normal;
            box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.20);
            background-position: top right;
            &:hover{
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.19) 100%), linear-gradient(204deg, rgba(252, 2, 38, 0.00) 19.47%, rgba(252, 2, 38, 0.81) 39.62%, #FC0226 55.94%), linear-gradient(0deg, rgba(252, 2, 38, 0.60) 0%, rgba(252, 2, 38, 0.60) 100%), url('../../img/home/1.png');
                background-blend-mode: normal, normal, color, normal, normal;
                background-position: top right;
                box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.20);
            }
        }
        &.accountTwo{
            background: linear-gradient(232deg, rgba(255, 255, 255, 0.72) 0.98%, rgba(255, 255, 255, 0.96) 58.1%), linear-gradient(0deg, rgba(252, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.40) 100%), url('../../img/home/2.png');
            // background-blend-mode: normal, saturation, color, normal, normal;
            box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.20);
            background-position: top right;
            &:hover{
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.19) 100%), linear-gradient(204deg, rgba(252, 2, 38, 0.00) 19.47%, rgba(252, 2, 38, 0.81) 39.62%, #FC0226 55.94%), linear-gradient(0deg, rgba(252, 2, 38, 0.60) 0%, rgba(252, 2, 38, 0.60) 100%), url('../../img/home/2.png');
                background-blend-mode: normal, normal, color, normal, normal;
                background-position: top right;
                box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.20);
            }
        }

        .name {
            color: #FC0226;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.875em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 36px */
            text-transform: capitalize;
            white-space: break-spaces;

            @media screen and (max-width: 667px) {
                font-size: 1.75em;
            }

            @media screen and (min-width: 1800px) {
                font-size: 1.8vw;
            }
        }

        .amount {
            color: #FC0226;
            leading-trim: both;
            text-edge: cap;
            font-size: 4em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 36px */
            text-transform: capitalize;
            white-space: break-spaces;

            @media screen and (max-width: 667px) {
                font-size: 3em;
            }

            @media screen and (min-width: 1800px) {
                font-size: 4.5vw;
            }
        }

        .minDeposit {
            color: #555;
            leading-trim: both;
            text-edge: cap;
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            @media screen and (min-width: 1441px) {
                font-size: 1.111vw;
            }
    
            @media screen and (max-width: 991px) {
                font-size: 1em;
            }
        }

        .description {
            color: #555;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            @media screen and (max-width: 667px) {
                font-size: 1em;
            }
            @media screen and (min-width: 1800px) {
                font-size: 1vw;
            }
        }
    }
    .inactive-left-corner{
        position: absolute;
        top: 0;
        left: 0;
        display: block;
    }

    .active-left-corner{
        position: absolute;
        top: 0;
        left: 0;
        display: none;
    }
    .rightaccount-box {
        @media screen and (max-width: 991px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &:hover{
            .name, .amount {
                color: #F9C258;
            }
            .description, .minDeposit{
                color: #fff;
            }
            .icon-active, .active-left-corner{
                display: block;
            }
            .icon-inactive, .inactive-left-corner{
                display: none;
            }
        }
    }
    .rightaccount-box-container{
        @media screen and (max-width: 667px){
            min-height: 50vh;
        }
    }
    .question {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.25em;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5em;
        /* 24px */
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 667px) {
            font-size: 1em;
        }
    }
}