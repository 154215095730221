.client {
    height: 90vh;

    @media screen and (max-width: 667px) {
        min-height: 130vh;
    }

    @media screen and (max-width: 991px) {
        height: auto;
        min-height: 90vh;
    }

    .quote-img {
        @media screen and (max-width: 520px) {
            width: 4.125em;
            height: 3.75em;
        }
    }

    .bottom-quote-img {
        @media screen and (max-width: 520px) {
            width: 10em;
            height: 9em;
            margin-right: -3em;
        }
    }
    .marquee-absolute{
        position: absolute;
        left: 0;
        bottom: 5%;
        z-index: 0;
    }

    .client-content {
        height: 90vh;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        @media screen and (max-width: 667px) {
            height: auto;
            min-height: 130vh;
        }

        @media  screen and (max-width: 667px) and (min-height: 750px) {
            height: auto;
            min-height: 80vh;
        }
    }

    .bgWhite {
        background-color: #ffffff;
        height: 90vh;

        @media screen and (max-width: 991px) {
            height: 30vh;
        }
    }

    .clientPaddingTop{
        @media screen and (min-width: 991px) {
            padding-top: 15vh;
        }
        
    }

    .sectionOne {
        @media screen and (max-width: 991px) {
            height: 20vh;

            .text-container {
                display: flex;
                height: 100%;
                align-items: center;
            }
        }

        @media screen and (max-width: 667px) {
            height: 30vh;
            .text-container {
                display: flex;
                height: 100%;
                align-items: center;
            }
        }

    }

    .sectionTwo {
        @media screen and (max-width: 991px) {
            height: 70vh;
        }

        @media screen and (max-width: 667px) {
            height: auto;
            min-height: 80vh;
        }
        @media screen and (max-width: 667px) and (min-height: 750px) {
            height: auto;
            min-height: 60vh;
        }

    }

    .client-mobile-container {
        @media screen and (min-width: 668px) and (max-width: 991px) {
            // margin-top: -5%;
            height: 60vh;
        }

        @media screen and (max-width: 667px) {
            margin-top: -10%;
            min-height: 70vh;
        }
    }

    .bgRed {
        background: url('../../img/company/clientBg.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 90vh;

        @media screen and (max-width: 991px) {
            height: 70vh;
        }

        @media screen and (max-width: 667px) {
            min-height: 110vh;
        }

        @media screen and (max-width: 667px) and (min-height: 750px) {
            min-height: 100vh;
        }

    }

    .client-trustedbytraders {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.875em;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: capitalize;

        @media screen and (max-width: 520px) {
            font-size: 1.125em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1.2vw;
        }
    }

    .client-hearclientsays {
        color: #FC0226;
        leading-trim: both;
        text-edge: cap;
        font-size: 2.875em;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-transform: capitalize;
        white-space: break-spaces;

        @media screen and (max-width: 520px) {
            font-size: 1.75em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }
    }

    .comment-box {
        background-image: url('../../img/home/commentBg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 40vh;

        @media screen and (max-width: 991px) {
            box-shadow: 0 0.5em 0.5em 0.4em rgba(0, 0, 0, 0.1);
        }
    }

    .comment-list {
        display: grid;
        grid-gap: calc(var(--gutter) * 1.5);
        // grid-template-columns: 1rem;
        // grid-template-rows: minmax(10rem, 1fr);
        grid-auto-flow: column;
        // grid-auto-columns: 320px; // small viewports

        @media (min-width: 800px) {
            grid-auto-columns: calc(var(--gutter) * 12);
        }

        @media (min-width: 1800px) {
            grid-auto-columns: calc(var(--gutter) * 24);
        }

        overflow-x: scroll;
        scroll-snap-type: x proximity;
        scroll-snap-points-x: repeat(100%);
        scroll-snap-type: mandatory;
        scroll-snap-destination: 100% 0%;
        // padding-bottom: calc(.75 * var(--gutter));
        // margin-bottom: calc(-.25 * var(--gutter));
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: 800px) {

            // yeah make this large-viewport only
            &:hover {
                .item {
                    opacity: 0.6;
                    filter: blur(2px);
                    // transition-delay: 0.3s;
                }
            }
        }


    }

    .client-message {
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }
    }

    .client-name {
        color: #F9C258;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.25em;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 24px */
        text-transform: capitalize;

        @media screen and (max-width: 520px) {
            font-size: 1.125em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }
    }

    .client-position {
        color: #555;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 21.6px */
        text-transform: capitalize;

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        &:hover {
            color: #000;
        }


    }

    .arrow {
        &:hover {
            svg path {
                fill: #FC0226;
            }

            svg circle {
                stroke: #FC0226;
            }
        }
    }

    .pagination {
        position: absolute;
        bottom: 10%;

        @media screen and (max-width: 667px) {
            bottom: 0%;
        }
    }

    .page-bullet {
        width: 5px;
        height: 5px;
        background-color: #ffffff;

        &.active {
            background-color: #F9C258;
            width: 4.375em;
        }
    }

    .marquee-name {
        color: #F9C258;
        leading-trim: both;
        text-edge: cap;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.60);
        font-size: 4em;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        /* 76.8px */
        text-transform: capitalize;

        @media screen and (max-width: 520px) {
            font-size: 2.5em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }

    }
}