.main-navbar {
    .navbar-brand{
        max-width: 40%;
        @media screen and (max-width: 1024px) {
            max-width: 30%;
        }
    }
    .header-logo {
        width: 35%;

        @media screen and (max-width: 767px) {
            // width: 40%;
            width: 100%;
        }
        @media screen and (min-width: 2100px) {
            width: 200px;
        }
        @media screen and (min-width: 3000px) {
            width: 300px;
        }

        @media screen and (max-width: 300px) {
            width: 80%;
        }
    }

    .bg-white {
        .lang-menu-text {
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: #000;

            @media screen and (min-width: 1800px) {
                font-size: .7vw;
            }
        }
    }

    .navbar-transparent {
        .lang-menu-text {
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: #fff;

            @media screen and (min-width: 992px) {
                font-size: 1.111vw;
            }

            @media screen and (max-width: 991px) {
                font-size: 1em;
            }

            @media screen and (min-width: 1800px) {
                font-size: .7vw;
            }
        }
    }

    .language-menu {
        
        &.active {
            .lang-menu-text {
                font-style: normal;
                font-weight: 400;
               
                line-height: normal;
                text-transform: capitalize;
                color: #fff;
                @media screen and (min-width: 1800px) {
                    font-size: .7vw;
                }
            }
        }
    }

    // .navbar-nav {
    //     .nav-link:not(.btn) {
    //         font-size: 1em;
    //         font-style: normal;
    //         font-weight: 400;
    //         line-height: normal;
    //         text-transform: capitalize;
    //         color: #fff;
    //     }
    // }

    .nav-link:not(.btn) {
        background-color: transparent !important;

        &:active,
        &:hover,
        &:focus {
            background-color: transparent !important;
        }
    }


    &.red-theme{
    .dropdown-menu {
        background: rgba(238, 0, 0, 0.70);
        backdrop-filter: blur(5px);
        margin-top: 0px;
        margin-bottom: 0px;
        min-width: 15rem;

        &::before {
            display: none;
        }

        .dropdown-item {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    }
    &.transparent-theme{
        .dropdown-menu {
            background: rgba(230, 230, 243, 0.96);
            backdrop-filter: blur(5px);
            margin-top: 0px;
            margin-bottom: 0px;
            min-width: 15rem;
    
            &::before {
                display: none;
            }
    
            .dropdown-item {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
        
    }

    .language-menu {
        .dropdown-menu {
            min-width: auto !important;
        }
    }

    &.transparent-theme {
        .menu-text {
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: #555;
            &:hover, &.active{
                color: #F9C258;
                font-weight: 700;
            }

            @media screen and (min-width: 1800px) {
                font-size: .7vw;
            }
        }

        .lang-menu-text {
            color: #555555;
            leading-trim: both;
            text-edge: cap;
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (min-width: 1800px) {
                font-size: .7vw;
            }
        }
    }

    &.red-theme {
        .menu-text {
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: #000;

            @media screen and (min-width: 1800px) {
                font-size: .7vw;
            }
            &:hover, &.active{
                color: #FC0226;
                font-weight: 700;
            }
        }
    }

    // .sidebar-hamburger {
    //     z-index: 999;
    // }

    @mixin transition {
        @for $i from 1 through 50 {
            &:nth-child(#{$i}) {
                transition: transform 1s #{$i * .08}s cubic-bezier(.29, 1.4, .44, .96);
            }
        }
    }

    @mixin alpha-attribute($attribute, $color, $background) {
        $percent: alpha($color) * 100%;
        $opaque: opacify($color, 1);
        $solid-color: mix($opaque, $background, $percent);
        #{$attribute}: $solid-color;
        #{$attribute}: $color;
    }

    .navbar {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    $main: #1184F0;
    $text: #FEFEFE;
    $contrast: darken($main, 10%);

    .drawer-list {
        // position: absolute;
        // transition: ease 0.6s all;

        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        transform: translate(100%, 0);
        -ms-transform: translatex(-100%);
        box-sizing: border-box;
        pointer-events: none;
        transition: width 475ms ease-out, transform 450ms ease;
        background: url('../../img/headerBackground/drawerBg.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 999 !important;


        // @media screen and (max-width: 767px) {
        //     padding-top: 20%;
        // }

        @media (min-width: 768px) {
            width: 100vw;

        }

        ul {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: auto;
            overflow-x: hidden;
            pointer-events: auto;
        }

        li {
            list-style: none;
            text-transform: uppercase;
            pointer-events: auto;
            white-space: nowrap;
            box-sizing: border-box;
            // transform: translatex(100vw);
            // -ms-transform: translatex(-100vw);

            &:last-child {
                margin-bottom: 1em;
            }

            a {
                text-decoration: none;
                // text-align: center;
                display: block;
                padding: .5em;
                // font-size: calc(24px - .5vw);


                @media (min-width: 768px) {
                    // text-align: right;
                    padding: .5rem;
                }

                &:hover {
                    cursor: pointer;
                    // @include alpha-attribute('background-color', rgba($main, 0.5), white);
                }
            }
        }
    }

    .hamburger-ui{
        &.active{
            display: inline-block;
        }   
        &.inactive{
            display: none;
        }
    }
    .hamburger-container {
        
        @media screen and (max-width: 767px) {
            top: 0.5rem;
            right: 15px;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            top: 0.5rem;
            right: 15px;
        }

        input.hamburger {
            display: none;

            &:checked {


                &~label {
                    >i {
                        background-color: transparent;
                        transform: rotate(90deg);

                        &:before {
                            transform: translate(-50%, -50%) rotate(45deg);
                        }

                        &:after {
                            transform: translate(-50%, -50%) rotate(-45deg);
                        }
                    }

                    close {
                        color: $text;
                        width: 100%;
                    }

                    open {
                        color: rgba(0, 0, 0, 0);
                        width: 0;
                    }
                }
            }
        }

        .drawer-list {
            &.active {
                transform: translatex(0);
                border-bottom-left-radius: 0;

                li {
                    transform: translatex(0);
                    @include transition;

                    a {
                        padding-right: 15px;
                    }
                }

                @media screen and (max-width: 991px) {
                    // transform: translate(1rem);
                }
            }
        }

        label.hamburger {
            z-index: 9999;
            position: relative;
            display: block;
            height: 25px;
            width: 2.375em;

            @media screen and (max-width: 767px) {
                width: 2.375em;
            }

            @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 2.375em;
            }

            @media screen and (max-width: 480px) {
                width: 1.5em;
            }

            &:hover {
                cursor: pointer;
            }

            text {

                close,
                open {
                    text-transform: uppercase;
                    font-size: .8em;
                    align-text: center;
                    position: absolute;
                    transform: translateY(50px);
                    text-align: center;
                    overflow: hidden;
                    transition: width .25s .35s, color .45s .35s;
                }

                close {
                    color: rgba(0, 0, 0, 0);
                    right: 0;
                    width: 0;
                }

                open {
                    color: $text;
                    width: 100%;
                }
            }

            >i {
                position: absolute;
                width: 100%;
                height: 3px;
                top: 50%;

                pointer-events: auto;
                transition-duration: .35s;
                transition-delay: .35s;

                background-color: #F9C258;

                background-color: #F9C258;

                &.homeHamburger {
                    &.unchecked {
                        background-color: #F9C258;
                    }
                }

                @media screen and (max-width: 767px) {
                    height: 2px;
                }


                &:before,
                &::after {
                    background-color: #F9C258;
                }

                &.homeHamburger {
                    &.checked {

                        &:before,
                        &::after {
                            background-color: #F9C258;
                        }
                    }

                    &.unchecked {

                        &:before,
                        &::after {
                            background-color: #F9C258;
                        }
                    }
                }

                &:before,
                &:after {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 3px;
                    left: 50%;
                    content: "";
                    transition: transform 0.35s;
                    transform-origin: 50% 50%;

                    @media screen and (max-width: 767px) {
                        height: 2px;
                    }
                }

                &:before {
                    transform: translate(-50%, -6px);
                }

                &:after {
                    transform: translate(-50%, 6px);
                }
            }
        }
    }

    .bg-white {
        .hamburger-container {
            .text-white {
                color: #05010E !important;
            }

            .unchecked {
                background-color: #F9C258 !important;

                &::before,
                &::after {
                    background-color: #F9C258 !important;
                }
            }
        }
    }

    .navbar-transparent {
        .hamburger-container {
            .text-white {
                color: #f3fff3 !important;
            }

            .homeHamburger.unchecked {
                background-color: #f3fff3 !important;

                &::before,
                &::after {
                    background-color: #f3fff3 !important;
                }
            }
        }
    }

    .icon {
        display: inline-block;
        width: 5vw;
        height: 4vw;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }

    .navbar-translate {
        z-index: 9999 !important;
    }

    .navbar-nav {
        z-index: 9999 !important;
    }

    .mobile-menu-list {
        padding-top: 20% !important;

        li {
            cursor: pointer;
        }

        li a {
            display: block;
            width: inherit;
            position: relative;
            line-height: 20px;
            // height: 40px;
            // padding: 10px;
            transition: all .51s ease;

            // color: red !important;
            &.dark {
                color: #ffffff !important;
            }

            &.light {
                color: #575757 !important;
            }
        }

        li>a:hover {
            color: #F9C258;
        }

        .dropdown-mobile-submenu>a:hover {
            color: #F9C258;
        }

        a {
            text-decoration: none !important;
        }

        .dropdown-mobile-submenu,
        .dropdown-mobile {
            a {
                &.active {
                    svg {
                        // transform: rotate(-180deg);
                    }
                }
            }

            svg {
                top: 5px;
                position: relative;
            }
        }

        .dropdown-mobile-menu {
            margin: 0px;
            padding: 0px;
        }

        .dropdown-mobile-menu li {
            background-color: transparent;

            &:last-child {
                margin-bottom: 1em;
            }
        }
    }

    @media screen and (min-width: 992px) {
        .navbar-nav .nav-item:not(:last-child) {
            margin-right: 1vw;
        }
    }

    .lang-dropdown-container .lang-menu-container {
        &::-webkit-scrollbar {
            width: .4em;
        }

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb {
            overflow: visible;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, .2);
        }
    }

    .social-icon{
        @media screen and (min-width: 1800px) {
            img {
                width: 2vw;
            }
        }
    }
}

.mobile-language {
    z-index: 9999 !important;

    .select-lang {
        z-index: 9999 !important;
    }
}

.lang-icon {
    width: 1.75em;
    aspect-ratio: 1;
}

.arrow-menu{
    @media screen and (min-width: 1800px) {
    }
}