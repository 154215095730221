.home {
    .header {
        background: url('../../img/headerBackground/home.png');
        background-size: cover;
        height: 100vh;
        background-repeat: no-repeat;

        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            height: 60vh;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            background-size: cover;
            background-position: 65% 75%;
            background-repeat: no-repeat;
            height: 80vh;
        }

        @media screen and (max-width: 767px) {
            background: url('../../img/headerBackground/homeMobile.png');
            background-position: right;
            background-repeat: no-repeat;
            height: 100vh;
        }

        .highresscreen {
            @media (min-width: 2000px) {
                &.col-lg-5 {
                    flex: 0 0 83.333333% !important;
                    max-width: 83.333333% !important;
                }
            }
        }

        .head-small-title {
            color: #F1F1F1;
            leading-trim: both;
            text-edge: cap;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (min-width: 1441px) {
                font-size: 1.111vw;
            }

            @media screen and (max-width: 991px) {
                font-size: 1em;
            }

            @media screen and (max-width: 520px) {
                color: #F1F1F1;
                leading-trim: both;
                text-edge: cap;
                font-size: 0.8125em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
            }
        }

        .head-big-title {
            color: #FFF;
            leading-trim: both;
            text-edge: cap;
            font-style: normal;
            font-weight: 600;
            font-size: 2.875em;
            line-height: 120%;
            text-transform: capitalize;

            @media screen and (min-width: 1800px) {
                font-size: 2.8vw;
            }

            @media screen and (max-width: 991px) {
                font-size: 2.875em;
            }

            @media screen and (max-width: 520px) {
                color: #FFF;
                leading-trim: both;
                text-edge: cap;
                font-size: 2em;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                text-transform: capitalize;
            }
        }

        .content-text-container {
            width: 99%;
        }

        .content-text {
            color: #E6E6F3;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 400;
            font-size: 1.125em;
            line-height: normal;

            @media screen and (min-width: 1800px) {
                font-size: 1vw;
            }

            @media screen and (max-width: 991px) {
                font-size: 1.125em;
            }

            @media screen and (max-width: 520px) {
                color: #E6E6F3;
                leading-trim: both;
                text-edge: cap;
                font-size: 1em;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .white-divider {
            width: 99%;
            height: .6px;
            background-color: #E6E6F3;
        }


    }

    .startTradeText {
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.25em;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5em;
        /* 24px */
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: .7vw;
        }

        @media screen and (max-width: 667px) {
            font-size: 1em;
        }
    }

    .whytradebost{
        min-height: 80vh;
        @media screen and (min-width: 768px) and (max-width: 991px) {
            min-height: 60vh;
        }
        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            min-height: 50vh;
        }

    }

    .connecting {
        background: url('../../img/home/connectingBg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        min-height: 80vh;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            background-size: cover;
            background-position: 65% 75%;
            background-repeat: no-repeat;
            min-height: 60vh;
        }

        @media screen and (max-width: 767px) {
            background: url('../../img/home/connectingBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            min-height: 50vh;
        }

        .section-title {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 2.875em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            text-transform: capitalize;

            .text-gold {
                color: #F9C258;
            }

            @media screen and (max-width: 520px) {
                color: #000;
                leading-trim: both;
                text-edge: cap;
                font-size: 1.75em;
                font-style: normal;
                font-weight: 600;
                white-space: break-all;
                line-height: normal;
            }

            @media screen and (min-width: 1800px) {
                font-size: 2.8vw;
            }
        }

        .section-content {
            color: #000;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-transform: lowercase;

            @media screen and (max-width: 520px) {
                color: #555;
                font-size: 1em;
                font-weight: 400;
            }

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
                
            }
        }
    }    

    .container{
        &.experience{
            @media screen and (max-width: 767px) {
                max-width: 100% !important;
            }
        }
    }
}

.contactusHome{
    padding-top: 14vh;
    @media screen and (max-width: 667px) {
        padding-top: 0px;
    }
}