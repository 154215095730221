.market {
    .header {
        height: 90vh;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 80vh;
        }

        @media screen and (max-height: 700px) {}

        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            height: 60vh;
        }
    }

    &.market {
        .header {
            background: url('../../img/headerBackground/markets.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;

            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background: url('../../img/headerBackground/markets.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .head-small-title {
        color: #F1F1F1;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        line-height: 1em;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 1.111vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1em;
        }

        @media screen and (max-width: 520px) {
            color: #F1F1F1;
            leading-trim: both;
            text-edge: cap;
            font-size: 0.8125em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    .head-big-title {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 600;
        font-size: 2.875em;
        line-height: 1.2em;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }

        @media screen and (max-width: 667px) {
            font-size: 1.5rem;
        }
    }

    .content-text-container {
        width: 99%;
    }

    .content-text {
        color: #E6E6F3;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }

        @media screen and (max-width: 667px) {
            font-size: .9rem;
        }
    }

    .white-divider {
        width: 99%;
        height: .6px;
        background-color: #E6E6F3;
    }

}

.accordion__title {
    color: #0C0C0C;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.875em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 36px */
    text-transform: capitalize;

    @media screen and (min-width: 1800px) {
        font-size: 2.083vw;
    }

    @media screen and (max-width: 667px) {
        font-size: 1.1rem;
    }
}

.accordion__description {
    color: #555;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 21.6px */

    @media screen and (min-width: 1800px) {
        font-size: .8vw;
    }

    @media screen and (max-width: 667px) {
        font-size: .9rem;
    }
}

.bgSubscriber {
    background-image: url('../../img/platform/subscriberBg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
}


.whytrade {
    background-color: #ffffff;
    background-image: url('../../img/market/whytradeBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    @media screen and (max-width: 520px) {
        background-image: url('../../img/market/whytradeBg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    .contentbg {
        background: url('../../img/company/ultimatebroker.png');
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 14px 30px 60px 0px rgba(123, 131, 137, 0.46);
        // height: 70vh;

        @media screen and (max-width: 767px) {
            background: url('../../img/company/ultimatebrokerMobile.png');
            background-size: cover;
            background-repeat: no-repeat;
            // height: auto;
        }
    }

    .section-title {
        color: #ffffff;
        leading-trim: both;
        text-edge: cap;
        font-size: 2.875em;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-transform: capitalize;

        .text-gold {
            color: #F9C258;
        }

        @media screen and (max-width: 520px) {
            color: #ffffff;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.75em;
            font-style: normal;
            font-weight: 600;
            white-space: break-all;
            line-height: normal;
        }

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }
    }

    .section-content {
        color: #E6E6F3;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media screen and (max-width: 520px) {
            color: #ffffff;
            font-size: 1em;
        }

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }
    }

    .white-stroke {
        width: 100%;
        height: 6px;
        background-color: #ffffff;
    }

    .phone-container {
        img {
            width: 100%;
            aspect-ratio: 1;
            @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 80%;
            }
        }
    }

    .content-bold {
        color: #0C0C0C;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.5em;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3em;

        @media screen and (max-width: 520px) {
            font-size: 1.125em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }
    }

    .content-light {
        color: #555;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3em;

        @media screen and (max-width: 520px) {
            font-size: 1em;
        }

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }
    }

    .sectiontwo {
        min-height: 60vh;

        @media screen and (min-width: 1800px) {
            min-height: auto;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
            min-height: 30vh;
        }
    }
}

.energytrading{
    background: url('../../img/market/energytradingBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background-size: cover;
        background-position: 65% 75%;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px) {
        background: url('../../img/market/energytradingBg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.indicestrading{
    background: url('../../img/market/indicestradingBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background-size: cover;
        background-position: 65% 75%;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px) {
        background: url('../../img/market/indicestradingBg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.forextrading{
    background: url('../../img/market/forextradingBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background-size: cover;
        background-position: 65% 75%;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px) {
        background: url('../../img/market/forextradingBg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.goldtrading{
    background: url('../../img/market/goldtradingBg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background-size: cover;
        background-position: 65% 75%;
        background-repeat: no-repeat;
    }

    @media screen and (max-width: 767px) {
        background: url('../../img/market/goldtradingBg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.section-title {
    color: #0C0C0C;
    leading-trim: both;
    text-edge: cap;
    font-size: 2.875em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;

    @media screen and (max-width: 667px) {
        font-size: 1.75em;
    }
    @media screen and (min-width: 1800px) {
        font-size: 2.8vw;
        
    }
}

.section-small-title {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 24px */
    text-transform: capitalize;
    @media screen and (min-width: 1800px) {
        font-size: .7vw;
    }
    @media screen and (max-width: 667px) {
        font-size: 1em;
    }
}

.section-description {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 667px) {
        font-size: 1em;
    }
    @media screen and (min-width: 1800px) {
        font-size: 1vw;
    }
}