.traderiskfree {
    background: url('../../img/traderiskfree/bgLandscape.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    height: 100vh;
    position: relative;

    @media screen and (min-width: 1024px) and (min-height: 1200px) {
        height: 60vh;
    }

    @media screen and (max-width: 767px) {
        background: url('../../img/traderiskfree/bgPotrait.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        background-position: 50% 50%;
        .overlay{
            background: rgba(255,255,255,0.6);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }
        .container{
            position: relative;
            // z-index: 99 !important;
        }
    }

    @media screen and (max-width: 520px) {
        background: url('../../img/traderiskfree/bgPotrait.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 120vh;
        background-position: 50% 50%;
        .overlay{
            background: rgba(255,255,255,0);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
        }
        .container{
            position: relative;
            // z-index: 99 !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../img/traderiskfree/bgPotrait.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        height: 60vh;
    }

    @media screen and (min-width: 2100px) {
        background: url('../../img/traderiskfree/bgLandscape.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: top left;
        height: 70vh;
    }


    .tradeTitle {
        background-image: url('../../img/traderiskfree/textBg.png');
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 5em;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 120%;
        -webkit-font-smoothing: antialiased;

        @media screen and (min-width: 1441px) {
            font-size: 4vw;
        }

        @media screen and (max-width: 767px) {
            background-image: url('../../img/traderiskfree/textBg.png');
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 2.875em;
            text-transform: capitalize;
            font-weight: 700;
            line-height: 120%;
            -webkit-font-smoothing: antialiased;
        }

        @media screen and (max-width: 300px) {
            font-size: 2.5em;
        }

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }
        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            font-size: 3em;
        }

    }

    .tradeTitleSolidPrime {
        background-image: url('../../img/traderiskfree/textBg.png');
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 5em;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 120%;
        -webkit-font-smoothing: antialiased;

        @media screen and (max-width: 767px) {
            background-image: url('../../img/traderiskfree/textBg.png');
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 3.5em;
            text-transform: capitalize;
            font-weight: 700;
            line-height: 120%;
            -webkit-font-smoothing: antialiased;
        }

        @media screen and (min-width: 1441px) {
            font-size: 4vw;
        }

        @media screen and (max-width: 300px) {
            font-size: 3em;
        }

        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            font-size: 2em;
        }
    }

    .tradeSubTitle {
        color: #555;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 767px) {
            font-size: .95em;
        }
        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            font-size: 1em;
        }
    }

    .startBtn {
        background: #FC0226;
        transition: transform 500ms ease-out;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid #FC0226;
        width: 60%;

        .startBtnText {
            color: #ffffff;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-weight: 600;
            line-height: normal;

            @media screen and (min-width: 1800px) {
                font-size: .8vw;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, #fff, transparent);
            transition: 0.5s;
            transform: rotate(-45deg);
        }

        &:hover,
        &:active,
        &:focus {
            background: #FC0226 !important;
            box-shadow: 0 0.5em 0.5em -0.4em rgba(252, 2, 38, 0.5);
            transform: translateY(-0.25em);

            &::before {
                left: 100%;
            }
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }
        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            width: 100%;
        }
    }
}