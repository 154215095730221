.trading {
    .header {
        height: 90vh;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            height: 80vh;
        }

        @media screen and (max-height: 700px) {}

        @media screen and (min-width: 1024px) and (min-height: 1200px) {
            height: 60vh;
        }
    }

    &.trading {
        .header {
            background: url('../../img/headerBackground/trading.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;

            @media screen and (min-width: 768px) and (max-width: 991px) {
                background-size: cover;
                background-position: 65% 75%;
                background-repeat: no-repeat;
            }

            @media screen and (max-width: 767px) {
                background: url('../../img/headerBackground/trading.png');
                background-size: auto 110%;
                background-position:  65% 75%;
                background-repeat: no-repeat;
            }
        }
    }

    .head-small-title {
        color: #F1F1F1;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 400;
        font-size: 1em;
        line-height: 1em;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 1.111vw;
        }

        @media screen and (max-width: 991px) {
            font-size: 1em;
        }

        @media screen and (max-width: 520px) {
            color: #F1F1F1;
            leading-trim: both;
            text-edge: cap;
            font-size: 0.8125em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    .head-big-title {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-style: normal;
        font-weight: 600;
        font-size: 2.875em;
        line-height: 1.2em;
        text-transform: capitalize;

        @media screen and (min-width: 1800px) {
            font-size: 2.8vw;
        }

        @media screen and (max-width: 667px) {
            font-size: 1.5rem;
        }
    }

    .content-text-container {
        width: 99%;
    }

    .content-text {
        color: #E6E6F3;
        leading-trim: both;
        text-edge: cap;
        font-size: 1.125em;
        font-style: normal;
        font-weight: 400;

        @media screen and (min-width: 1800px) {
            font-size: 1vw;
        }

        @media screen and (max-width: 667px) {
            font-size: .9rem;
        }
    }

    .white-divider {
        width: 99%;
        height: .6px;
        background-color: #E6E6F3;
    }

}

.accordion__title {
    color: #0C0C0C;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.875em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 36px */
    text-transform: capitalize;

    @media screen and (min-width: 1800px) {
        font-size: 2.083vw;
    }

    @media screen and (max-width: 667px) {
        font-size: 1.1rem;
    }
}

.accordion__description {
    color: #555;
    leading-trim: both;
    text-edge: cap;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 21.6px */

    @media screen and (min-width: 1800px) {
        font-size: .8vw;
    }

    @media screen and (max-width: 667px) {
        font-size: .9rem;
    }
}

.bgSubscriber {
    background-image: url('../../img/platform/subscriberBg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
}

.execution{
    min-height: 85vh;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        min-height: 70vh;
    }
}