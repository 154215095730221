.footers {
    background: url('../../img/footer/footerBg.png');
    background-blend-mode: normal, multiply, normal, normal;
    background-size: cover;

    .redBottomDivider{
        border-top: 0.6px solid #ffffff;
        @media screen and (max-width: 520px) {
            opacity: .5;
        }
    }

    .redDivider {
        border-top: 0.6px solid #ffffff;
        @media screen and (max-width: 991px){
            border-top: none;
        }
    }
    .footer-link{
        // width: 14.28%;
        // @media screen and (max-width: 767px) {
        //     width: 100%;
        // }
        // @media screen and (min-width: 768px) and (max-width: 991px) {
        //     width: 25%;
        // }
    }

    .parentMenu {
        color: #FFF;
        cursor: pointer;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-size: 1em;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
        &:hover, &.active{
            color: #F9C258;
            font-weight: 700;
            text-decoration: none !important;
        }
        @media screen and (min-width: 1800px) {
            font-size: .9vw;
        }
        @media screen and (max-width: 520px) {
            color: #FFFFFF;
            leading-trim: both;
            text-edge: cap;
            font-size: 1.125em;
            font-style: normal;
            font-weight: 500;
            text-transform: capitalize;
        }
    }

    .childMenu {
        color: #E6E6F3;
        leading-trim: both;
        text-edge: cap;
        font-size: 0.8125em;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        white-space: break-spaces;
        /* 14.3px */
        text-transform: capitalize;
        &:hover, &.active{
            color: #CDA351;
            text-decoration-line: none !important;
        }
        @media screen and (min-width: 1800px) {
            font-size: .7vw;
        }
        @media screen and (max-width: 520px) {
            color: #E6E6F3;
            leading-trim: both;
            text-edge: cap;
            font-size: 1em;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            text-transform: capitalize;
        }
    }

    .copyright {
        color: #E6E6F3;
        leading-trim: both;
        text-edge: cap;
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (min-width: 1441px) {
            font-size: .8vw;
        }

        @media screen and (max-width: 520px) {
            color: #fff;
            leading-trim: both;
            text-edge: cap;
            font-size: 0.8125em;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }

        &.bold{
            font-weight: 600;
        }
    }

    .social-icon{
        &:hover{
            path{
                fill: blue;
            }
        }
        @media screen and (min-width: 1441px) {
            img{
                width: 1.5vw;
            }
            
        }
    }

    .logo-mobile{
        height: 4em;
        width: auto;
    }

    .logo-word-mobile{
        height: 2em;
        width: auto;
    }
}